import React, { useContext, useEffect, Fragment } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Container from "layout/Container"
import ButtonGroup from "../../../Elements/ButtonGroup"
import FlexmedCashlessMechanicsModal from "../../../Elements/Modals/FlexmedCashlessMechanicsModal"

import { generateFormField } from "../../../Elements/Form/services/form"
import { FlexmedCashlessContext } from "../../FlexmedContext/FlexmedCashlessContext"
import { AppContext } from "../../../../context/AppContext"
import MechanicsStatic from "./MechanicsStatic"

let { parseFormField } = require("../../../../services/airtable")

const Mechanics = ({ pageContext }) => {
  const { flexmedCashlessState, flexmedCashlessDispatch } = useContext(
    FlexmedCashlessContext
  )
  const { dispatch } = useContext(AppContext)
  let formFields = pageContext.formFields
  let sectionFormFields = parseFormField(formFields)

  const handleSubmitCashlessMechanics = (values) => {
    flexmedCashlessDispatch({
      type: "SAVE_AGREE_TO_MECHANICS",
      payload: {
        ...values,
      },
    })
    flexmedCashlessDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...flexmedCashlessDispatch,
        ...values,
      },
    })
    navigate(pageContext.nextPath)
  }

  const handleCloseModal = () => {
    dispatch({
      type: "HIDE_MODAL",
    })
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          content: (
            <FlexmedCashlessMechanicsModal
              handleCloseModal={handleCloseModal}
            />
          ),
          hideCloseButton: true,
        },
      })
    }, 200)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    flexmedCashlessDispatch({
      type: "GET_CONTEXT_FROM_SESSION",
    })
    // eslint-disable-next-line
  }, [])

  let pageContent = pageContext?.pageContent?.[0]?.content
  return (
    <Layout {...pageContext?.module} pageContext={pageContext}>
      <Container mobile={12} tablet={10} desktop={6} isCentered>
        <Formik
          initialValues={{ ...flexmedCashlessState }}
          onSubmit={handleSubmitCashlessMechanics}
        >
          {({ values, setFieldValue }) => {
            let isNextDisabled = formFields.reduce((isNextDisabled, field) => {
              if (!!field.required)
                return isNextDisabled || !values?.[field?.name]?.length
              return isNextDisabled
            }, false)
            return (
              <Form>
                {sectionFormFields.map((section) => (
                  <Fragment>
                    <MechanicsStatic />
                    <div className="content">
                      {section?.fields.map((field) => {
                        if (!field?.referenceAnswer) {
                          return (
                            <Fragment>
                              {generateFormField({
                                formFields: section?.fields,
                                formField: { ...field },
                                values,
                                setFieldValue,
                              })}
                              {!!field?.addDividerAfterField && (
                                <hr className="has-background-light" />
                              )}
                            </Fragment>
                          )
                        }
                        return null
                      })}
                    </div>
                  </Fragment>
                ))}
                <ButtonGroup
                  back={{
                    link: pageContext.backPath,
                  }}
                  next={{
                    label: `Next: ${pageContext.module.next}`,
                    link: pageContext.nextPath,
                    disabled: !!isNextDisabled,
                    type: "submit",
                  }}
                />
              </Form>
            )
          }}
        </Formik>
      </Container>
    </Layout>
  )
}

export default Mechanics
