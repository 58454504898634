import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

import useModalImages from "./hooks/useModalImages"

const FlexmedCashlessMechanicsModal = ({ handleCloseModal }) => {
  const data = useModalImages()
  const image = data["fullYearRegimen"]?.childImageSharp?.fixed

  return (
    <div className="has-background-white">
      <div className="mx-2 my-2">
        <img
          src={image?.src}
          alt="fullYearRegimen"
          width={image?.width || "50%"}
          className="mt-1"
        />
        <div className="content has-text-left">
          <center>
            <p className="has-text-weight-bold mb-1 has-text-primary">
              FlexMed Cashless Coverage Reminder
            </p>
          </center>
          <p>
            The medicine benefit program covers{" "}
            <b className="has-text-primary">
              generic or branded ethical drugs only
            </b>
            . Over-the-counter meds, vitamins, and supplements are not covered
            in FlexMed Cashless.
          </p>

          <p>Some examples of covered meds are:</p>

          <div className="message is-success mb-1">
            <div className="message-body pl-0">
              <article className="media">
                <figure className="media-left has-text-left ml-0 mb-1">
                  <p className="image is-32x32">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="has-text-success ml-1"
                      size="2x"
                    />
                  </p>
                </figure>
                <div className="media-content">
                  <ul className="mt-0 ml-1">
                    <li>
                      <b>Maintenance medicines</b> (Common meds: Amlodipine,
                      Simvastatin, Losartan, Metformin)
                    </li>
                    <li>
                      <b>Antibiotics</b> (Common meds: Amoxicillin,
                      Co-trimoxazole, Cefuroxime)
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>

          <p>
            FlexMed Cashless order total will be deducted from your Php 2.5K
            quarterly allowance.
          </p>

          <p>
            Some examples of meds that are{" "}
            <b>
              <u>not</u>
            </b>{" "}
            covered are:
          </p>

          <div className="message mb-1">
            <div className="message-body pl-0">
              <article className="media">
                <figure className="media-left has-text-left ml-0 mb-1">
                  <p className="image is-32x32">
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      className="has-text-grey-light ml-1"
                      size="2x"
                    />
                  </p>
                </figure>
                <div className="media-content">
                  <ul className="mt-0 ml-1">
                    <li>
                      <b>Vitamins and supplements</b> (Common meds:
                      Multivitamins + Minerals, Vitamin C, Vitamin E)
                    </li>
                  </ul>
                </div>
              </article>
            </div>
          </div>

          <p>
            Most medicines can be picked up within 1-4 working days for Metro
            Manila sites, and 2-7 working days for non-Metro Manila sites.
          </p>
          <center>
            <button
              onClick={handleCloseModal}
              className="button is-orange has-text-weight-bold is-medium my-1"
            >
              Okay, I understand
            </button>
          </center>
        </div>
      </div>
    </div>
  )
}

export default FlexmedCashlessMechanicsModal
