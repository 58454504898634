import { useStaticQuery, graphql } from "gatsby"

const useModalImages = () => {
  const data = useStaticQuery(graphql`
    {
      fullYearRegimen: file(
        relativePath: { eq: "icons/me__full-year-regimen.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      freeMedicineIcon: file(
        relativePath: { eq: "free_medicine_default.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      laboratoryTestsIcon: file(
        relativePath: { eq: "laboratory_tests_default.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      doctorConsultationsIcon: file(
        relativePath: { eq: "doctor_consultations_default.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      nutritionCounselingIcon: file(
        relativePath: { eq: "nutrition_counseling_default.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      otherLifestyleBenifitsIcon: file(
        relativePath: { eq: "lifestyle_benefits_default.png" }
      ) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useModalImages
