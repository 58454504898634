import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"

import mechanicsContent from "./utils/mechanics.json"
import styles from "./utils/mechanics.module.scss"
import {
  EligibilityIcon,
  HexFilled,
  HexOutline,
  SafetyIcon,
  ReceiptIcon,
  FormularyIcon,
  ReminderIcon,
} from "./utils/svg"

const MechanicsStatic = () => {
  const handleIcons = (name) => {
    switch (name) {
      case "Eligibility":
        return <EligibilityIcon />
      case "Coverage":
        return <SafetyIcon />
      case "Receipts":
        return <ReceiptIcon />
      case "Reminders":
        return <ReminderIcon />
      default:
        return null
    }
  }

  const getClassNames = (headerContentIndex) => {
    const dataType = getDataType(headerContentIndex)
    let classNames = "default"
    if (dataType === "boolean") {
      return (classNames += "__boolean")
    } else if (dataType === "array") {
      return (classNames += "__array")
    }
    return classNames
  }

  const getDataType = (data) => {
    if (typeof data === "boolean") {
      return "boolean"
    } else if (Array.isArray(data)) {
      return "array"
    } else {
      return "other"
    }
  }

  const renderContent = (data) => {
    const dataType = getDataType(data)
    if (dataType === "boolean") {
      return data ? (
        <FontAwesomeIcon icon={faCheckCircle} className="is-secondary" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} className="is-secondary" />
      )
    } else if (dataType === "array") {
      return data.map((element, index) => (
        <span key={index}>
          {element}
          {index !== data.length - 1 && ", "}
        </span>
      ))
    } else {
      return data
    }
  }

  return (
    <div className={classNames(styles["lists__wrapper"])}>
      {mechanicsContent.map((content, index) => {
        return (
          <div key={index} className={classNames(styles["list"])}>
            <div className={classNames(styles["label__wrapper"])}>
              <div className={classNames(styles["hex__container"])}>
                <div className={classNames(styles["hex__wrapper"])}>
                  <div className={classNames(styles["hex__filled"])}>
                    <HexFilled />
                  </div>
                  <div className={classNames(styles["hex__outlined"])}>
                    <HexOutline />
                  </div>
                  <div className={classNames(styles["hex__icon"])}>
                    {handleIcons(content?.name)}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "has-text-secondary has-text-weight-bold",
                  styles["list__title"]
                )}
              >
                {content?.name}
              </div>
            </div>
            <div className={classNames(styles["list__paragraph"])}>
              <div
                className={classNames(
                  "has-text-secondary has-text-weight-bold",
                  styles["list__title"]
                )}
              >
                {content?.name}
              </div>
              <ul className={classNames(styles["text__wrapper"])}>
                {content?.content?.map((list, index) => {
                  if (Array.isArray(list)) {
                    return (
                      <ul className={classNames(styles["text__list"])}>
                        {list.map((listItem, listIndex) => (
                          <li className={classNames(styles["text__nested"])}>
                            <div>•</div>
                            <p>{listItem}</p>
                          </li>
                        ))}
                      </ul>
                    )
                  } else {
                    return (
                      <li key={index} className={classNames(styles["text"])}>
                        <div>•</div>
                        <p>{list}</p>
                      </li>
                    )
                  }
                })}
              </ul>
              {content?.table && (
                <div>
                  <table>
                    <thead>
                      <tr>
                        {content?.table.map((item, index) => {
                          if (item.type === "header") {
                            return item?.content?.map(
                              (headerItem, headerIndex) => {
                                if (
                                  headerItem === "MedEnsure" ||
                                  headerItem === "FlexMed"
                                ) {
                                  return (
                                    <th
                                      key={headerIndex}
                                      className="default__header"
                                    >
                                      {headerItem}
                                    </th>
                                  )
                                } else {
                                  return (
                                    <th key={headerIndex} className="default">
                                      {headerItem}
                                    </th>
                                  )
                                }
                              }
                            )
                          }
                          return null
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {content?.table.map((item, index) => {
                        if (item.type === "body") {
                          const headerItems = content.table.filter(
                            (item) => item.type === "header"
                          )
                          return headerItems.map((headerItem, headerIndex) => (
                            <tr key={headerIndex}>
                              {headerItem.content.map(
                                (headerContent, headerContentIndex) => (
                                  <td
                                    key={headerContentIndex}
                                    className={getClassNames(
                                      item.content[headerContentIndex + 1]
                                    )}
                                  >
                                    {renderContent(
                                      item.content[headerContentIndex + 1]
                                    )}
                                  </td>
                                )
                              )}
                            </tr>
                          ))
                        }
                        return null
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MechanicsStatic
